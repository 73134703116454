export const TITLE = "Password Generator"
export const DESCRIPTION = "Use this tool to instantly create secure, random passwords."
export const COPIED = "Copied"
export const COPY_PASSWORD = "Copy Password"
export const ADVANCED = "ADVANCED"
export const PASSWORD_LENGTH = "Password Length"
export const LOWERCASE = "Lowercase"
export const UPPERCASE = "Uppercase"
export const NUMBERS = "Numbers"
export const SYMBOLS = "Symbols"
export const WORDS = "Word Based"
export const WORDS_TITLE = "Generate hyphenated words as password"
export const EXCLUDE_CHARACTERS = "Exclude look-alike characters"
export const EXCLUDE_CHARACTERS_TITLE = "Exclude Characters"
export const DO_NOT_INCLUDE = "Do not include"
export const DO_NOT_INCLUDE_PLACEHOLDER = "Custom characters to exclude"
export const GENERATE_PASSWORD = "Generate Password"
export const NO_OPTION_ERROR_TEXT = "Select at least one option"
export const SYMBOL_ONLY_ERROR_TEXT = "You can not select only the symbol option"
export const FAQ_TITLE = "Frequently Asked Questions"
export const FAQ = [
  {
    question: 'What is cs.wi365.co?',
    answer: 'A password generator is a web tool that creates unique and random passwords based on security recommendations. The best password generators are the ones that allow you to customize settings according to your requirements. Our tool has plenty of options for the best result.'
  },
  {
    question: 'Whats the use?',
    answer: 'It is not a good idea to use your date of birth, anniversary or your dogs name as passwords. They are easily deciphered by a little social engineering. In cs.wi365.co you will be able to obtain a solid independent password like a rock.'
  },
  {
    question: 'Is this generator safe to use?',
    answer: 'Passwords generated are not stored or shared anywhere else since they are created locally on your computer. Remember not to leave the page unattended to once you\'re done creating and copying your password.'
  },
  {
    question: 'What are the requirements for a strong password?',
    answer: 'A strong password is one that is hard to crack. It needs to be as random as possible and have more than eight characters. Also, it must include a combination of upper and lower cases or be a mixture of symbols and a passphrase.'
  },
  {
    question: 'The importance of using a proper password',
    answer: 'According to recent reports, 80% of attacks due to pirates or hackers have been used for unsafe or stolen passwords. To defend oneself, it is highly recommended to have secure passwords, indecipherable if possible. These passwords are created by combining letters, numbers and symbols and are even more secure if they are unique, i.e. if they are not used in more than one place.'
  },
  {
    question: 'What passwords should not be used?',
    answer: 'Passwords that you’ve used before, Passwords that are the same as your username, Anything that includes personal information, Words from a dictionary, Common phrases, Popular passwords like password, 123456, qwerty, abc123, iloveyou, 111111, and so on, Anything shorter than 12 characters.'
  },
  {
    question: 'How long should my password be?',
    answer: 'Your passwords should be at least 12 characters long. However, the longer the password is, the better.'
  },
  {
    question: 'Do I need a unique password for every account?',
    answer: 'Yes! Using the same password across multiple accounts is a huge no-no. If hackers learn the password to one of your accounts, they’ll have your password for all the others, too.'
  },
  {
    question: 'Can a strong password be hacked?',
    answer: 'Creating completely uncrackable passwords is becoming a challenge. But you can stand a better chance against hackers by avoiding bad password practices. They include reusing passwords for different accounts, common keyword patterns (such as qwerty), common acronyms (such as ymca), or repeating characters (such as zzz111). Stay on track with your passwords by using our online password generator and password strength checker tools.'
  },
  {
    question: 'Terms of Use',
    answer: 'This web is just a help to create more secure passwords, but It does not guarantee your total security. Choosing a password is your responsibility, and this website is not responsible for the consequences of improper use.'
  },
]